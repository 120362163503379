<template>
  <div>
    <el-card class="font-14">
      <div class="flex flex-column gap-20">
        <div class="flex flex-align-center flex-wrap gap-30">
          <div class="flex flex-align-center">
            <div class="color-606266 font-bold" style="width:80px;text-align:right">关键字：</div>
            <el-input style="width:250px" placeholder="公司名称" v-model="company"></el-input>
          </div>
        </div>
        <div class="flex flex-align-center flex-wrap gap-30">
          <div class="flex flex-align-center">
            <div class="color-606266 font-bold" style="width:80px;text-align:right">客户来源：</div>
            <el-select v-model="source" style="width:200px" clearable @clear="source=null">
              <el-option v-for="(v,i) in sourceList" :key="i" :value="v.Id" :label="v.FromName"></el-option>
            </el-select>
          </div>
          <div class="flex flex-align-center">
            <div class="color-606266 font-bold" style="width:80px;text-align:right">合作状态：</div>
            <el-select v-model="state" style="width:200px" clearable @clear="state=null">
              <el-option v-for="(v,i) in stateList" :key="i" :value="v.value" :label="v.label"></el-option>
            </el-select>
          </div>
          <div class="flex flex-align-center">
            <div class="color-606266 font-bold" style="width:80px;text-align:right">客户类型：</div>
            <el-select v-model="type" style="width:200px" clearable @clear="type=null">
              <el-option v-for="(v,i) in typeList" :key="i" :value="v.Id" :label="v.TypeName"></el-option>
            </el-select>
          </div>
        </div>
        <div class="flex flex-align-center flex-wrap gap-30">
          <div class="flex flex-align-center">
            <div class="color-606266 font-bold" style="width:80px;text-align:right">客户级别：</div>
            <el-select v-model="level" style="width:200px" clearable @clear="level=null">
              <el-option v-for="(v,i) in levelList" :key="i" :value="v.value" :label="v.label"></el-option>
            </el-select>
          </div>
          <div class="flex flex-align-center">
            <div class="color-606266 font-bold" style="width:80px;text-align:right">负责人：</div>
            <el-select v-model="charge" style="width:200px" clearable @clear="charge=null">
              <el-option v-for="(v,i) in chargeList" :key="i" :value="v.Id" :label="v.EmployeeName+`(${v.Phone})`"></el-option>
            </el-select>
          </div>
          <div>
            <el-button type="primary" @click="query">查询</el-button>
          </div>
          <buttonPermissions :datas="'sellManageAdd'">
            <el-button type="primary" @click="add">添加客户</el-button>
          </buttonPermissions>
          <buttonPermissions :datas="'sellManageImportFile'" >
            <el-button @click="importFile">批量导入</el-button>
          </buttonPermissions>
          <div>
            <el-button type="text" @click="reset">重置筛选条件</el-button>
          </div>
        </div>
      </div>
      <el-table :data="tablelist" v-loading="tableloading" class="margin-top-20" tooltip-effect="dark popper-max500">
        <el-table-column prop="" label="客户编码" fixed width="120">
          <template slot-scope="scope">
            <div class="tap-409eff ellipsis-two" @click="toDetails(scope.row)">
              {{scope.row.CRMCustomerNo}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="" label="公司名称" fixed width="150" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="tap-409eff ellipsis" @click="toDetails(scope.row)">
              {{scope.row.CompanyName}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="TypeName" label="客户类型" width="120">
          <template slot-scope="scope">
            <div>
              {{scope.row.TypeName||'--'}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="CRMCustomerLevelValue" label="客户级别" width="120">
          <template slot-scope="scope">
            <div>
              {{scope.row.CRMCustomerLevelValue||'--'}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="FromName" label="客户来源" width="120">
          <template slot-scope="scope">
            <div>
              {{scope.row.FromName||'--'}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="" label="销售区域" width="150">
          <template slot-scope="scope">
            <div>
              <div v-if="scope.row.SellCity">
                {{scope.row.SellProvince}}/{{scope.row.SellCity}}
              </div>
              <div v-else>--</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="CooperateStateValue" label="合作状态" width="120">
          <template slot-scope="scope">
            <div>
              {{scope.row.CooperateStateValue||'--'}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="" label="商机" width="120">
          <template slot-scope="scope">
            <div>
              <div class="tap-409eff ellipsis-two" @click="toDetails(scope.row,'1')" v-if="scope.row.BusinessOpportunityCount">
                {{scope.row.BusinessOpportunityCount}}
              </div>
              <div v-else>--</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="" label="负责人" width="120">
          <template slot-scope="scope">
            <div>
              <div>{{scope.row.EmployeeName||'--'}}</div>
              <div>{{scope.row.Phone||'--'}}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="" label="源线索" width="120" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="tap-409eff ellipsis" v-if="scope.row.ClueCompanyName" @click="toclueDataDetails(scope.row)">
              {{scope.row.ClueCompanyName}}
            </div>
            <div v-else>--</div>
          </template>
        </el-table-column>
        <el-table-column prop="" label="销售备注" width="150" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="ellipsis">
              {{scope.row.SellRemark||'--'}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="" label="联系人" width="120">
          <template slot-scope="scope">
            <div>
              <div class="ellipsis">{{scope.row.ContactName||'--'}}</div>
              <div>{{scope.row.ContactPhone||'--'}}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="" label="固话" width="120">
          <template slot-scope="scope">
            <div>
              <div v-if="!scope.row.TelephoneAreaCode&&!scope.row.Telephone">--</div>
              <div v-else>
                {{[scope.row.TelephoneAreaCode,scope.row.Telephone].filter(v=>v).join('-')}}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="" label="营业执照名称" width="150" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="tap-409eff ellipsis" @click="toDetails(scope.row)">
              {{scope.row.BusinessLicenseName||'--'}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="" label="所在地区" width="150">
          <template slot-scope="scope">
            <div>
              <div v-if="scope.row.Area" class="ellipsis">
                {{scope.row.Province}}/{{scope.row.City}}/{{scope.row.Area}}
              </div>
              <div v-else>--</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="Address" label="详细地址" width="150">
          <template slot-scope="scope">
            <div class="ellipsis">
              {{scope.row.Address||'--'}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="AddTime" label="添加时间" width="170"></el-table-column>
        <el-table-column prop="" label="操作" width="250px" fixed="right">
          <template slot-scope="scope">
            <div class="flex flex-align-center flex-wrap gap-10">
              <div>
                <el-button type="text" @click="toDetails(scope.row)">详情</el-button>
              </div>
              <buttonPermissions :datas="'sellManageChangeChare'" v-if="![4].includes(scope.row.CooperateState)">
                <el-button type="text" @click="toChangeCharge(scope.row)">更换负责人</el-button>
              </buttonPermissions>
              <buttonPermissions :datas="'sellManageViod'" v-if="![3,4].includes(scope.row.CooperateState)">
                <el-button type="text" @click="toVoid(scope.row)">作废</el-button>
              </buttonPermissions>
              <buttonPermissions :datas="'sellManageStop'" v-if="[3].includes(scope.row.CooperateState)">
                <el-button type="text" @click="toStop(scope.row)">终止合作</el-button>
              </buttonPermissions>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination style="text-align:right" class="margin-top-10" v-if="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </el-card>

    <el-dialog :visible.sync="chargeShow" title="更换销售负责人" width="500px" custom-class="dialog-body-paddingTop-10">
      <div class="form" style="margin-top:0px">
        <div class="form-label label-star">将负责人更换为：</div>
        <el-select v-model="chareMsg.MallEmployeeId" class="margin-top-10" style="width:100%">
          <el-option v-for="(v,i) in chargeList" :key="i" :value="v.Id" :label="v.EmployeeName+`(${v.Phone})`"></el-option>
        </el-select>
      </div>
      <div class="flex flex-align-center gap-10 margin-top-20">
        <el-button style="margin-left:auto" @click="chargeShow=false">关闭</el-button>
        <el-button type="primary" @click="confirmCharge">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="viodShow" title="作废客户" width="500px" custom-class="dialog-body-paddingTop-10">
      <div class="form" style="margin-top:0px">
        <div class="flex gap-10">
          <div class="form-label label-star">作废原因：</div>
          <div class="flex-1">
            <el-select v-model="viodMsg.CancelReasonType" style="width:100%" placeholder="请选择">
              <el-option v-for="(v,i) in viodList" :key="i" :value="v.value" :label="v.label"></el-option>
            </el-select>
            <el-input v-model="viodMsg.CancelReason" v-if="viodMsg.CancelReasonType==30" type="textarea" :rows="2" class="margin-top-10"
             style="width:100%" maxlength="100"></el-input>
          </div>
        </div>
        <div class="flex gap-10 margin-top-10">
          <div class="form-label">备注：</div>
          <el-input v-model="viodMsg.CancelRemark" type="textarea" :rows="4" style="width:100%" maxlength="500"></el-input>
        </div>
      </div>
      <div class="flex flex-align-center gap-10 margin-top-20">
        <el-button style="margin-left:auto" @click="viodShow=false">关闭</el-button>
        <el-button type="primary" @click="confirmViod">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="stopShow" title="终止合作" width="500px" custom-class="dialog-body-paddingTop-10">
      <div class="form" style="margin-top:0px">
        <div class="form-label label-star">终止合作备注：</div>
        <el-input v-model="stopMsg.TerminateCooperateRemark" type="textarea" :rows="4" class="margin-top-10" style="width:100%" maxlength="500"></el-input>
      </div>
      <div class="flex flex-align-center gap-10 margin-top-20">
        <el-button style="margin-left:auto" @click="stopShow=false">关闭</el-button>
        <el-button type="primary" @click="confirmStop">确定</el-button>
      </div>
    </el-dialog>

    <!-- 批量导入客户 -->
		<el-dialog :visible.sync="importExtendShow" title="批量导入客户" width="650px" v-loading="importExtendLoading" @closed="closeImportShow"
			custom-class="dialog-body-paddingTop-10">
			<div class="yellowBox">
				请下载客户导入模板，根据文件中的填写须知，按要求完善数据再导入
				<el-button @click="getFileOriginal" class="margin-left-20">下载模板</el-button>
			</div>
			<div v-if="extendFile.IsExistProgress" class="file-box">
				<div class="flex flex-align-center flex-column" v-if="!extendFile.IsComplete">
					<div class="flex flex-align-center">
						<img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/exce-distributionInquiry20240513.png" style="width:30px" alt="">
						<div class="margin-left-20 margin-right-20 loading">
							<div></div>
							<div></div>
							<div></div>
							<div></div>
						</div>
						<img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/images/melyshop/menuIcon/xiaoshouguanli/kehuguanli.png" style="width:30px" alt="">
					</div>
					<div style="text-align:center">
						<div class="color-606266 margin-top-20">数据导入中...</div>
						<div class="color-999999 margin-top-10">导入需要一定时间，请勿关闭操作框</div>
					</div>
				</div>
				<div style="text-align:center" v-else>
					<div class="color-606266">导入完成</div>
					<div class="color-999999 margin-top-10">成功导入{{extendFile.SuccessCount}}条，失败{{extendFile.FailCount}}条<span v-if="extendFile.FailCount">，可下载导入失败数据</span></div>
					<el-button class="margin-top-20" v-if="extendFile.FailCount>0" @click="downFile">下载导入失败数据</el-button>
				</div>
			</div>
			<el-upload :action="uploadFile" accept=".xls,.xlsx" class="file-box" :show-file-list="false" :before-upload="beforeUpload"
				 :on-success="handleUploadSuccess" v-else>
				<div v-if="extendFile.filePath" class="color-999999">
					<i class="el-icon-document"></i>
					<div class="margin-top-10 margin-bottom-20">{{extendFile.fileName}}</div>
				</div>
				<div v-else class="color-999999">
					<div>
						点击按钮上传文件，
					</div>
					<div class="margin-top-10 margin-bottom-10">
						支持xls、xlsx格式
					</div>
				</div>
				<el-button>上传文件</el-button>
			</el-upload>
			<div style="text-align:center" class="margin-top-30">
				<el-button type="primary" style="width:300px" v-if="hasConfirmBtn" @click="confirmImport">确认导入</el-button>
			</div>
		</el-dialog>
  </div>
</template>

<script>
import {
  crmCustomerlist,
  crmCustomercooperateStateEdit,
  crmCustomercrmCustomerCancel,
  crmCustomerchangeEmployeeId,
  crmCustomerCromlist,
  crmCustomerTypelist,
  crmCustomerimport,
  crmCustomerimportiscomplete,
  crmCustomerisExistsProgress
} from "@/api/sv3.0.0"
import buttonPermissions from '@/components/buttonPermissions';
import {
  employeelistbystate
} from "@/api/TurnTomySelf"
import config from '@/config/index';
export default {
  components: {
    buttonPermissions
  },
  data () {
    return {
      company:'',
      source:null,
      sourceList:[],
      state:null,
      stateList:[
        {value:null,label:'全部'},
        {value:1,label:'未合作'},
        {value:2,label:'有意向'},
        {value:3,label:'合作中'},
        {value:4,label:'终止合作'},
      ],
      type:null,
      typeList:[],
      level:null,
      levelList:[
        {value:null,label:'全部'},
        {value:1,label:'重要客户'},
        {value:2,label:'普通客户'},
        {value:3,label:'一般客户'},
        {value:4,label:'其他'},
      ],
      charge:null,
      chargeList:[],
      tablelist:[{}],
      tableloading:false,
      currentPage:1,
      pagesize:20,
      total:0,
      rowmsg:{},

      chargeShow:false,
      chareMsg:{},
      viodShow:false,
      viodMsg:{},
      viodList:[
        {value:10,label:'客户暂时无需求'},
        {value:20,label:'联系不上客户'},
        {value:30,label:'其他'},
      ],
      stopShow:false,
      stopMsg:{},
      importExtendShow: false,
      uploadFile: config.UPLOAD_ZIP,
      extendFile: {
        filePath:'',
        fileName:'',
        importId:0,
        SuccessCount:0,
        FailCount:0,
        IsComplete:false,
        IsExistProgress:false,
      },
      hasConfirmBtn: false,
      importExtendLoading: false,
      timeOut: null
    }
  },
  mounted () {
    this.getCharce()
    this.getType()
    this.getsource()
    this.gettablelist()
  },
  methods: {
    closeImportShow(){
      this.extendFile = {
        filePath:'',
        fileName:'',
        importId:0,
        SuccessCount:0,
        FailCount:0,
        IsComplete:false,
        IsExistProgress:false,
      }
      this.hasConfirmBtn = false;
      this.getList();
      clearTimeout(this.timeOut)
    },
    downFile(){
      window.open(location.origin+'/pc/cRMCustomer/errorDownload?Id='+this.extendFile.importId)
    },
    async confirmImport(){
      try{
        this.importExtendLoading = true
        let res = await crmCustomerimport({
          ExcelFilePath:this.extendFile.filePath,
          ExcelFileName:this.extendFile.fileName,
        })
        if(res.IsSuccess){
          this.extendFile.importId = res.Result.Id
          this.extendFile.IsExistProgress = true
          this.hasConfirmBtn = false
          this.getResult()
        }
      }finally{
        this.importExtendLoading = false
      }
    },
    async getResult(){
      try{
        let res = await crmCustomerimportiscomplete({
          Id:this.extendFile.importId
        })
        if(res.IsSuccess){
          if(res.Result.IsComplete){
            for(let key in res.Result){
              this.extendFile[key] = res.Result[key]
            }
          }else{
            this.timeOut = setTimeout(()=>{
              this.getResult()
            },1000)
          }
        }
      }finally{
    
      }
    },
    handleUploadSuccess(file){
      this.extendFile.filePath = file[0]
      this.hasConfirmBtn = true
    },
    beforeUpload(file){
      var type = file.name.split('.')[1];
      if (['xls', 'xlsx'].indexOf(type) > -1){
        this.extendFile.fileName = file.name;
        return true;
      }else{
        this.$message.error('请选择正确的文件上传')
        return false;
      }
    },
    getFileOriginal(){
      let url = `https://cdn.dkycn.cn/${encodeURIComponent('客户导入模板文件')}.xlsx`
      window.open(url)
    },
    async getSchedule(){
      try{
        this.importExtendLoading = true
        let res = await crmCustomerisExistsProgress()
        if(res.IsSuccess){
          // console.log(res.Result)
          this.extendFile.IsExistProgress = res.Result.IsExistProgress
          this.extendFile.importId = res.Result.Id
          if(this.extendFile.IsExistProgress){
            this.getResult()
          }
        }
      }finally{
        this.importExtendLoading = false
      }
    },
    importFile(){
      this.importExtendShow = true
      this.getSchedule()
    },
    toclueDataDetails(row){
      this.$router.push({
        name: 'clueDataDetails',
        query: {
          id:row.CRMClueId
        }
      })
    },
    async getCharce(){
      try{
        let res = await employeelistbystate({
          EmployeeState:-1,
        })
        if(res.IsSuccess){
          this.chargeList = res.Result
        }
      }finally{

      }
    },
    async getType(){
      try{
        let res = await crmCustomerTypelist({
          Skip:0,
          Take:9999
        })
        if(res.IsSuccess){
          this.typeList = [
            {Id:null,TypeName:'全部'},
            ...res.Result.Results
          ]
        }
      }finally{

      }
    },
    async getsource(){
      try{
        let res = await crmCustomerCromlist({
          Skip:0,
          Take:9999
        })
        if(res.IsSuccess){
          this.sourceList = [
            {Id:null,FromName:'全部'},
            ...res.Result.Results
          ]
        }
      }finally{

      }
    },
    toDetails(row,tab){
      this.$router.push({
        path:'/Customer/SellCustomerDetail',
        query:{
          Id:row.Id,
          tab:tab
        }
      })
    },
    async confirmStop(){
      if(!this.stopMsg.TerminateCooperateRemark){
        this.$message.error('请输入备注')
        return
      }
      try{
        let res = await crmCustomercooperateStateEdit({
          Id:this.rowmsg.Id,
          CooperateState:4,
          ...this.stopMsg,
        })
        if(res.IsSuccess){
          this.$message.success('操作成功')
          this.stopShow = false
          this.gettablelist()
        }
      }finally{
        //
      }
    },
    toStop(row){
      this.rowmsg = JSON.parse(JSON.stringify(row))
      this.stopMsg = {
        TerminateCooperateRemark:''
      }
      this.stopShow = true
    },
    async confirmViod(){
      if(!this.viodMsg.CancelReasonType){
        this.$message.error('请选择作废原因')
        return
      }
      try{
        let res = await crmCustomercrmCustomerCancel({
          Id:this.rowmsg.Id,
          ...this.viodMsg,
        })
        if(res.IsSuccess){
          this.$message.success('操作成功')
          this.viodShow = false
          this.gettablelist()
        }
      }finally{
        //
      }
    },
    toVoid(row){
      this.rowmsg = JSON.parse(JSON.stringify(row))
      this.viodMsg = {
        CancelReasonType:null,
        CancelReason:'',
        CancelRemark:''
      }
      this.viodShow = true
    },
    async confirmCharge(){
      if(!this.chareMsg.MallEmployeeId){
        this.$message.error('请选择负责人')
        return
      }
      try{
        let res = await crmCustomerchangeEmployeeId({
          Id:this.rowmsg.Id,
          ...this.chareMsg,
        })
        if(res.IsSuccess){
          this.$message.success('操作成功')
          this.chargeShow = false
          this.gettablelist()
        }
      }finally{
        //
      }
    },
    toChangeCharge(row){
      this.rowmsg = JSON.parse(JSON.stringify(row))
      this.chareMsg = {
        MallEmployeeId:null
      }
      this.chargeShow = true
    },
    async gettablelist(){
      try{
        this.tableloading = true
        let res = await crmCustomerlist({
          Keywords:this.company,
          CooperateState:this.state,
          CRMCustomerFromId:this.source,
          CRMCustomerLevel:this.level,
          CRMCustomerTypeId:this.type,
          MallEmployeeId:this.charge,
          Skip:(this.currentPage-1)*this.pagesize,
          Take:this.pagesize,
        })
        if(res.IsSuccess){
          this.tablelist = res.Result.Results
          this.total = res.Result.Total
        }
      }finally{
        this.tableloading = false
      }
    },
    handleSizeChange(e){
      this.pagesize = e
      this.currentPage = 1
      this.gettablelist()
    },
    handleCurrentChange(e){
      this.currentPage = e
      this.gettablelist()
    },  
    add(row){
      this.$router.push({
        path:'/Customer/SellCustomerEdit',
        query:{
          Id:row.Id
        }
      })
    },
    query(){
      this.currentPage = 1
      this.gettablelist()
    },
    reset(){
      this.company = ''
      this.source = null
      this.state = null
      this.type = null
      this.level = null
      this.charge = null
      this.query()
    }
  }
}
</script>

<style lang='less' scoped>
.yellowBox{
	background: #FDF6EC;
	padding: 15px ;
	font-size: 14px;
	color: #FDA23C;
}
.file-box{
	border: 1px solid #DCDFE6;
	width: 500px;
	height: 172px;
	margin: 20px auto;
	display: flex;
	align-items: center;
	justify-content: center;
}
.tap-409eff{
  color: #409eff;
  cursor: pointer;
}
/deep/tr{
  background-color: #f8f8f9
}
</style>